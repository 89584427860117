import React, { useState, useEffect } from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import Axios from "axios"
import { PusherProvider } from "react-pusher-hoc"
import Pusher from "pusher-js"
import { SnackbarProvider } from "notistack"
// import Typography from "@material-ui/core/Typography"
import Slide from "@material-ui/core/Slide"
import makeStyles from "@material-ui/styles/makeStyles"
import { persistor, store } from "../../redux/store"
import theme from "../../config/theme"
import history from "../../common/history"
import Routes from "./router"
import Loader from "../../components/Loader/Loader"
import Error from "../Error/Error"
import { Auth0Provider } from "../../common/react-auth0-spa"

const useStyles = makeStyles({
  snackbarRoot: {
    "& > div": {
      color: "#2F2F2F",
      backgroundColor: "#FFFFFF",
    },
  },
})

const getAuthUrl = () => {
  const {
    auth: { userdata },
  } = store.getState()
  const requestUserId = userdata.auth_user_id
  return `${
    process.env.REACT_APP_API_BASEURL
  }/api/pusher/auth?request_user_id=${requestUserId || ""}`
}

// app_id: "1193543"
// key = "b9642e58a61c0babd114"
// secret = "d15efa17d202fae31c66"
// cluster = "eu"

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

const renderLoading = () => {
  return <Loader />
}

const getQueryVariable = (variable) => {
  const query = window.location.search.substring(1)
  const vars = query.split("&")
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=")
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}

const App = () => {
  const classes = useStyles()
  const loadingComponent = renderLoading()
  const [load, setLoad] = useState(false)
  const [loadError, setLoadError] = useState(null)
  const [myConnection, setConnection] = useState("")

  const renderError = () => {
    // return (
    //   <Typography variant="h4">
    //     {loadError?.message || "Unknown Error While loading"}
    //   </Typography>
    // )
    return (
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <Error />
        </ThemeProvider>
      </React.StrictMode>
    )
  }

  const loadAuthTenant = async () => {
    try {
      setLoad(true)
      const domain = window.location.hostname
      // console.log("domain", domain)
      const url = `${process.env.REACT_APP_CORE_API_BASEURL}tenant/domain/${domain}`
      const response = await Axios.get(url)
      setLoad(false)
      // console.log("response", response)
      if (response.status === 200 && response.data) {
        setConnection(response.data)
        localStorage.setItem("tenantData", JSON.stringify(response.data))
      } else {
        setLoadError({ message: "Error Loading Tenant" })
      }
    } catch (error) {
      // console.log(error)
      setLoad(false)
      setLoadError(error)
    }
  }

  const loadQueryParam = () => {
    const error = getQueryVariable("error")
    if (error !== false) {
      setLoadError({ message: error })
    }
  }

  useEffect(() => {
    // eslint-disable-next-line
    loadAuthTenant(),
    loadQueryParam()
  }, [])

  const renderApp = () => {
    const authEndpoint = getAuthUrl()
    const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_APP_ID, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      authEndpoint,
    })
    const anchorOrigin = {
      vertical: "top",
      horizontal: "right",
    }
    return (
      <SnackbarProvider
        maxSnack={5}
        anchorOrigin={anchorOrigin}
        TransitionComponent={Slide}
        classes={{
          root: classes.snackbarRoot,
        }}
      >
        <PusherProvider value={pusherClient}>
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
            audience={process.env.REACT_APP_AUTH0_AUDIENCE}
            redirect_uri={window.location.origin}
            connection={myConnection.connection}
            useRefreshTokens
            onRedirectCallback={onRedirectCallback}
          >
            <React.StrictMode>
              <ThemeProvider theme={theme}>
                <Routes history={history} />
              </ThemeProvider>
            </React.StrictMode>
          </Auth0Provider>
        </PusherProvider>
      </SnackbarProvider>
    )
  }

  return (
    <Provider store={store}>
      <PersistGate loading={loadingComponent} persistor={persistor}>
        {load && loadingComponent}
        {!load && loadError && renderError()}
        {!load && !loadError && renderApp()}
      </PersistGate>
    </Provider>
  )
}

export default App
