import { combineReducers } from "redux"
import auth from "./auth/reducer"
import api from "./api/reducer"
import task from "./task/reducer"
import programme from "./programme/reducer"
import document from "./document/reducer"
import template from "./template/reducer"

const rootReducer = combineReducers({
  auth,
  api,
  task,
  programme,
  document,
  template,
})

export default rootReducer
