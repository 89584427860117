import React, { Component } from "react"
import Error from "../Error/Error"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // console.log("_ERROR", error)
    // console.error("_ERROR", error)
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      // console.error("_ERROR", this.state)
      // console.error("_ERROR", this.state)
      return <Error />
    }

    return this.props.children
  }
}

export default ErrorBoundary
