import React, { Suspense, lazy } from "react"
import PropTypes from "prop-types"
import { Router } from "react-router-dom"
import { AnimatedSwitch } from "react-router-transition"
import ErrorBoundary from "./ErrorBoundry"
import Loader from "../../components/Loader/Loader"
import PrivateRoute from "./PrivateRoute"
// import { publicRoutes } from "./Routes"

const Dashboard = lazy(() => import("../Dashboard/Dashboard"))
// const GDashboard = lazy(() => import("../Gantt/Dashboard"))

// const Denied = () => {
//   return <h2>Access Denied</h2>
// }

const Routes = (props) => {
  const { history } = props
  // console.log("Routes", history)
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router history={history}>
          <AnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 0 }}
            atActive={{ opacity: 1 }}
            className="switch-wrapper"
          >
            {/* {publicRoutes.map((route, index) => (
              <PrivateRoute
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                path={route.path}
                exact={route.exact}
                // component={route.component}
              >
                <route.component />
              </PrivateRoute>
            ))} */}
            {/* <Route path="/denied">
              <Denied />
            </Route> */}
            <PrivateRoute path="/" component={Dashboard} roleLevel={6}>
              {/* <Dashboard /> */}
            </PrivateRoute>
          </AnimatedSwitch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  )
}

Routes.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Routes
