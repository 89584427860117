const settings = {
  coreUrl: process.env.REACT_APP_CORE_API_BASEURL,
  baseUrl: process.env.REACT_APP_API_BASEURL,
  proxyUrl: process.env.REACT_APP_API_PROXYURL,
  useProxy: process.env.REACT_APP_API_USE_PROXY,
  imagesAwsPath: process.env.REACT_APP_IMAGES_AWS_PATH,
  maxFileNameCharacters: 10,
  api: "/api",
  endpoints: {
    login: "/login",
    getTemplates: "/templates",
    // getProjects: (orgId, status) => `/get-all-projects/${orgId}/${status}`,
    getProjects: "/get-all-projects",
    getProject: (id) => `/projects/${id}`,
    createProject: "/create-project",
    getProjectTaskCount: "/get-project-task-count",
    getProjectTaskList: "/get-project-task-list",
    updateProject: (id) => `/update-project/${id}`,
    dashboardAllProjects: (id) => `/dashboard-all-projects/${id}`,
    dashboardAllProjectsById: (orgId, id) =>
      `/dashboard-all-projects/${orgId}/${id}`,
    timelineProjectsById: (orgId, id) => `/timeline-projects/${orgId}/${id}`,
    timelineProjects: (orgId) => `/timeline-projects/${orgId}`,
    timelineProjectsList: (orgId, id = false) =>
      `/timeline-projects-list/${orgId}${id ? `/${id}` : ""}`,
    getAllStatus: "/task_statuses",
    updateTask: (id) => `/project_tasks/${id}`,
    addActivity: "/project_task_activities",
    // getAllComment: (id) => `/get-project-task-activities/${id}/comment`,
    // getAllActivity: (id) => `/get-project-task-activities/${id}/activity`,
    getAllComment: () => `/get-project-task-activities`,
    getAllActivity: () => `/get-project-task-activities`,
    getTask: (id) => `/project_tasks/${id}`,
    // getDocumentByTask: (id) => `/get-documents/${id}`,
    getDocumentByTask: () => `/get-documents`,
    createDocument: "/documents",
    getDocumentsByProject: "/get-documents-by-project",
    getPrimaryTask: (id) => `/get-primary-task-list/${id}`,
    createTask: "/project_tasks",
    projectLogo: "/project-logo",
    getAssigneeList: (id) => `/get-assignee-list/${id}`,
    getCompanyList: (id) => `/get-company-list/${id}`,
    getProjectStatusList: (id) => `/get-project-status-list/${id}`,
    getDocumentTypesList: "/get-documents-types",
    getDependencyTypeList: "/get-project-task-depdendencies-types",
    organizations: {
      list: `${process.env.REACT_APP_CORE_API_BASEURL}organisation/list`,
    },
    getTasksList: "/get-task-list",
    getClientAssigneeList: (orgId, companyId) =>
      `${process.env.REACT_APP_CORE_API_BASEURL}organisation/admin/list/${companyId}`,
    // `/get-client-assignee-list/${orgId}/${companyId}`,
    getProjectRagStatusList: "/get-project-rag-status-list",
    getProjectTasksPriorityList: "/get-project-tasks-priority-list",
    createProgramme: "/programme",
    updateProgramme: (id) => `/programme/${id}`,
    deleteProgramme: (id) => `/programme/${id}`,
    getProgramme: (programmeId) => `/programme/${programmeId}`,
    getAllPrgramme: "/programme/all",
    getProgramStatusList: "/programme/get-status-list",
    getProgrammeProjects: (programmeId) =>
      `/programme/project/list/${programmeId}`,
    addProgrammeProjects: "/programme_projects",
    removeProgramme: "/programme/project",
    getProgramDashboardTimeline: (orgId) => `/programme/timeline/${orgId}`,
    programmeProjectDashboard: (programmeId) =>
      `/programme/projects/dashboard/${programmeId}`,
    programProjectTimeline: (programmeId) =>
      `/programme/projects/timeline/${programmeId}`,
    getDocumentsByOrg: (organisationId) =>
      `${process.env.REACT_APP_CORE_API_BASEURL}document/organisation/${organisationId}`,
    getDocumentStatusList: `${process.env.REACT_APP_DOC_API_BASEURL}document/status/list`,
    updateDocumentStatus: `${process.env.REACT_APP_DOC_API_BASEURL}document/revision/status`,
    searchProject: (projectName) => `/project/${projectName}`,
    searchTemplate: (templateName) => `/template/${templateName}`,
    templateImport: "/template/import",
    templateLogo: "/template/logo",
    getTemplateStatus: "/template/status",
    getTemplateTypes: "/template/types",
    deleteTemplate: (id) => `/templates/${id}`,
    taskSubtask: "/project-tasks/sub-task",
    removeTaskSubTask: (subTask) => `/project-tasks/sub-task/${subTask}`,
    getTaskSubtask: (taskId) => `/project-tasks/sub-tasks/${taskId}`,
    getTaskWithoutSubTask: (projectId) =>
      `/project-tasks/tasks-without-parents/${projectId}`,
    documentAssignToTask: "/document/link-to-task",
    getNotifications: (userId) =>
      `${process.env.REACT_APP_COMMS_SERVICE_API_BASEURL}api/notifications/${userId}`,
    deleteNotification: (id) =>
      `${process.env.REACT_APP_COMMS_SERVICE_API_BASEURL}api/notification/${id}`,
  },
  feature: {
    editProject: process.env.REACT_APP_EDIT_PROJECT || false,
  },
}

export default settings
