import { createBrowserHistory } from "history"
// import ReactGA from "react-ga"
import GA4React from "ga-4-react"

const history = createBrowserHistory()
// ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE)

// const historyListerner = (location) => {
//   console.log("historyListerner", location.pathname)
//   if (GA4React.isInitialized()) {
//     const instance = GA4React.getGA4React()
//     if (instance) {
//       instance.pageview(location.pathname)
//     }
//   }
//   // ReactGA.set({ page: location.pathname })
//   // ReactGA.pageview(location.pathname)
// }
// history.listen(historyListerner)

export default history
