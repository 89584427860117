import React, { useEffect } from "react"
import { Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { useAuth0 } from "../../common/react-auth0-spa"
import { canAccess } from "../../common/authorization"

// eslint-disable-next-line
const PrivateRoute = ({ component: Component, path, roleLevel, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0()
  const userRoles = rest.auth.userdata["https://saffronios.com//roles"]
  // Check current user has access based on user role level and level required for component
  // const userRoleLevel = getUserRoleLevel(userRoles[0])

  let userCanAccess = false

  if (userRoles && userRoles[0]) {
    userCanAccess = canAccess(roleLevel, userRoles[0])
  }

  useEffect(() => {
    if (loading || isAuthenticated) {
      return
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      })
    }
    fn()
  }, [loading, isAuthenticated, loginWithRedirect, path])

  const render = (props) => {
    // console.log("_role_render_props", Component)
    const authenticated =
      isAuthenticated === true ? <Component {...props} /> : null
    if (authenticated === null) {
      return null
    }

    // if (userCanAccess) {
    return authenticated
    // }
    // console.log("render private route method")
    // return <Redirect to="/denied" />
  }

  // console.log("_role_render", render)
  return <Route path={path} render={render} {...rest} />
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(PrivateRoute)
