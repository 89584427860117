import React, { useState, useEffect, useContext } from "react"
import createAuth0Client from "@auth0/auth0-spa-js"

import { bindActionCreators } from "redux"
import { connect, useDispatch } from "react-redux"
import authActions from "../redux/reducers/auth/actions"

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const dispatch = useDispatch()
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)
  // const {authActions: { setUserData }} = useState()

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()

        // saffronios.com/app_metadata.id
        setUser(user)
        const token = await auth0FromHook.getTokenSilently()
        const idToken = await auth0FromHook.getIdTokenClaims()

        // console.log("_USER_ROLES ID TOKEN", idToken)
        // console.log("_USER_ROLES TOKEN", token)
        // Set User id
        const authUserId = idToken["https://saffronios.com/app_metadata"].id
        localStorage.setItem("saffron-token", token)
        //   setUserData(token, user);
        user.auth_user_id = authUserId

        // Zend desk token
        const zendDeskToken =
          idToken["https://saffronios.com/zendDeskToken"].token
        localStorage.setItem("saffron-zd-token", zendDeskToken)

        // TODO - Add id token to user data
        dispatch(authActions.setUserData(token, user))
        // console.log("_TOKEN_SET_")
        // console.log(token)

        // console.log("_TOKEN_ID_")
        // console.log(idToken)
        // return;
      }

      setLoading(false)
      // return;
    }
    initAuth0()
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client.getUser()
    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }
  // console.log("auth context")
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})
const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(authActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth0Provider)
